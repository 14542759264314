import { ChangeDetectorRef, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { TranslateService } from '@ngx-translate/core';
import { CGdsInstallationRow } from '@way-lib-jaf/rowLoader';
import * as conceptLoader from '@way-lib-jaf/conceptLoader';
import { JafConcept } from '@way-lib-jaf/concept';
import { CGdsEvenementChauffeur } from '@way-lib-jaf/conceptLoader';
import { Gds } from '@way-lib-jaf/gds';
import { JafRow } from '@way-lib-jaf/row';
import { WayDivers } from '../../../installation/interfaces/waypartner';
import { InfoCompte } from '../../interfaces/limoChauffeur';
import { WayMode, WayModeService } from '../way-mode.service';
import { STORAGE_KEY_INS } from '../../constant';

/**
 * @type: the expected database operation
 * @name: the name of the concept ex: C_Gen_Mission
 * @row: the content of the row
 */
export interface ObjectSynchro {
  type: 'insert' | 'delete' | 'update',
  name: string, // concept name
  row: object
}
export interface ListeSynchro {
  [key: string]: ObjectSynchro[];
}

export interface GdsPayload {
  limo: string,
  listeSynchro: ObjectSynchro[]
};

/**
 * @param gdsPayload: the payload expected by the server
 * @param listeSynchroSend: a backup of the listeSynchro in case of request fail
 * @param nb: number of row to synchronize no mater the database
 */
export interface SynchronisePayload {
  gdsPayload: GdsPayload[],
  listeSynchroSend: {
    [key: string]: ObjectSynchro[]
  },
  nb: number
}

/**
 * @param start_since: new Date().getTime()
 * @param timeout: return of a setTimeout
 */
export interface SynchroniseCache {
  start_since: number,
  timeout    : number,
}

@Injectable({
  providedIn: 'root',
})
export class ConceptManager {
  public nbAppel = 0;

  public champGet = {};

  public listeConcept = {};

  public synchroDone = false;

  public synchroEnCours = false;

  public onSynchroCallback: Function;

  public databases = [];

  public actionSynchro = '/gdsv3/synchronize';

  public configConcept = [];

  public services = new Map<string, JafConcept>();

  public launchRow = [];

  public conceptEvc: CGdsEvenementChauffeur;

  public readonly INS_GROUP_WAYP = 'PARTNER';

  public readonly INS_GROUP_WAYD = 'INTERNE';

  private _infos: Array<InfoCompte> = [];

  private listeEveIdExclus = {};

  private loadDataDeja;

  private synchronisecache: SynchroniseCache = {
    start_since: 0,
    timeout    : null,
  };

  private listeSynchro: ListeSynchro = {};

  private _launchFlag = false;

  private _changeDetectorRef: ChangeDetectorRef;

  private onConnexionBind = [];

  private onLoadBind = [];

  private _isloaded = false;

  private _constantes = {};

  public environment;

  public chauffeur;

  public postSaveCallbacks = [];

  constructor(
    // public logger: NGXLogger,
    public storage: Storage,
    public http: HttpClient,
    public gds: Gds,
    public translate: TranslateService,
    public toastController: ToastController,
    public wayModeService: WayModeService,
  ) {}

  get infos(): Readonly<InfoCompte[]> {
    return this._infos;
  }

  onConnexion() {
    this.onConnexionBind.forEach((f) => {
      f();
    });
  }

  setOnConnexion(callback) {
    this.onConnexionBind.push(callback);
  }

  setOnLoad(callback) {
    if (this._isloaded) {
      callback();
    } else {
      this.onLoadBind.push(callback);
    }
  }

  setChauffeur(chauffeur) {
    this.chauffeur = chauffeur;
  }

  getChauffeur() {
    return this.chauffeur;
  }

  setOnEachLoad(callback) {
    this.onLoadBind.push(callback);
  }

  onLoadExecute() {
    if (this._isloaded) {
      this.onLoadBind.forEach((callback) => {
        callback();
      });
    }
  }

  addDataBase(database) {
    if (!this.databases.includes(database)) {
      this.databases.push(database);
      this.storage.get(`${database}.listeSynchro`).then((listeSynchro: ObjectSynchro[]) => {
        this.getListeSynchro(database).concat(listeSynchro);
      });
      this.storage.get(`${database}.constantes`).then((constantes) => {
        this._constantes[database] = constantes ?? [];
      });
      Object.keys(this.listeConcept).forEach((nameClass) => {
        const concept = this.getConcept(nameClass);
        if (concept) {
          concept.rebuildIndexs();
        }
      });
    }
  }

  addService(concept) {
    this.services.set(concept.name, concept);
  }

  getInstallationPrincipal(): CGdsInstallationRow {
    return this.getMainInfoCompte()?.installation;
  }

  getDivers(): WayDivers {
    return this.getMainInfoCompte()?.divers;
  }

  getMainInfoCompte(): InfoCompte {
    return this.infos?.find(
      (info: InfoCompte): boolean =>
        info.installation.INS_GROUPE ===
        (this.wayModeService.getMode() === WayMode.WAY_D
          ? this.INS_GROUP_WAYD
          : this.INS_GROUP_WAYP),
    );
  }

  init(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.storage
        .get(STORAGE_KEY_INS)
        .then((infos: Array<InfoCompte>) => {
          this.setInfosAux(infos);

          this.conceptEvc = this.getConcept('C_Gds_EvenementChauffeur');
          if (this.conceptEvc) {
            this.conceptEvc.reloadLastEveId();
          }

          if (this.infos.length) {
            resolve(true);
          }
          resolve(false);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  setInfosAux(limos: InfoCompte[], save = true) {
    this._infos = [];
    limos?.forEach((info: InfoCompte) => {
      const infoNew = info;
      this.addDataBase(infoNew.installation.INS_COMPTE);
      infoNew.installation = new CGdsInstallationRow(
        this.getConcept('C_Gds_Installation'),
        info.installation,
      );
      this._infos.push(infoNew);
    });
    if (save) {
      this.saveInfo();
    }
  }

  saveInfo() {
    this.storage.set(STORAGE_KEY_INS, this.infos).catch((error) => {
      console.error(`error storage.set(${STORAGE_KEY_INS})`, error);
    });
  }

  setInfosInstallation(installationId: string, info: InfoCompte) {
    const installationIndex        = this.infos.findIndex((i: InfoCompte) => {
      return i.installation.INS_ID === installationId;
    });
    this._infos[installationIndex] = info;
    this.saveInfo();
  }

  getInfos(): Promise<InfoCompte[]> {
    return this.storage.get(STORAGE_KEY_INS);
  }

  getInstallations(): CGdsInstallationRow[] {
    return this.infos.map((info: InfoCompte) => {
      return info.installation;
    });
  }

  setFlagActif(database: string, flag) {
    const limo = this.getInstallation(database);
    if (limo.LCH_FLAG_ACTIF !== flag) {
      limo.LCH_FLAG_ACTIF = flag;
      this.storage.set(STORAGE_KEY_INS, this.infos);
    }
  }

  getInstallation(database: string) {
    const tab = this.infos.find((i: InfoCompte) => {
      return i.installation.INS_COMPTE === database;
    });
    return tab || null;
  }

  launchIfNeeded() {
    if (!this._launchFlag) {
      this._launchFlag = true;
      this.launch();
    }
  }

  launchBuildByRow(row: JafRow) {
    if (!this.launchRow.includes(row)) {
      this.launchRow.push(row);
      this.launchIfNeeded();
    }
  }

  launchBuildByNameField(champ: string) {
    this.services.forEach((service: JafConcept) => {
      service.launchBuildByNameField(champ);
    });
    this.launchIfNeeded();
  }

  launch() {
    if (this.launchRow.length > 0) {
      const liste    = this.launchRow.slice();
      this.launchRow = [];
      liste.forEach((row) => {
        row.executeBuilds();
      });
      if (this.launchRow.length > 0) {
        this.launch();
      }
    }

    this.services.forEach((concept: JafConcept) => {
      concept.makeActions();
    });

    this.detectChanges();
    this._launchFlag = false;
  }

  detectChanges() {
    if (this._changeDetectorRef) {
      this._changeDetectorRef.detectChanges();
    }
  }

  setDectectorRef(detector: ChangeDetectorRef) {
    this._changeDetectorRef = detector;
  }

  getDatabase() {
    return this.databases && this.databases[0] ? this.databases[0] : 'nodatabase';
  }

  getDatabases() {
    return this.databases;
  }

  getClassName(name: string) {
    return name.replace(/_/g, '');
  }

  getConcept(name) {
    if (this.listeConcept[name]) {
      return this.listeConcept[name];
    }

    if (undefined !== conceptLoader[this.getClassName(name)]) {
      const concept = new conceptLoader[this.getClassName(name)](this, this.http, this.storage);
      if (concept instanceof JafConcept) {
        this.listeConcept[name] = concept;
        concept.init();
        return this.listeConcept[name];
      }
    }

    throw new Error(`Concept Unkown : ${name}`);
  }

  /**
   * Download data from the server
   * Than method pay attention to upload local data before to get the fresh one (see: synchronise)
   * @param actionLoadData have to be defined in app.component via controller extend each app can different
   * @param params the payload
   * @param callback old school next of a subscribe
   * @param errorCallback old school error of a subscribe
   */
  loadData(actionLoadData, params?, callback?: (arg0?: unknown) => void, errorCallback?) {
    const now = new Date().getTime();
    if (this.loadDataDeja && now - this.loadDataDeja < 5000 ) {
      return;
    }
    this.loadDataDeja = now;
    if (!this.synchroDone) {
      return;
    }
    this.gds.post('divers', actionLoadData, params).subscribe(
    (data) => {
      this.loadDataDeja = false;
      if (data) {
        this.setDatas(data, true); // on remplace toutes les données locale par les données recues
        this._isloaded = true
      }
      if (typeof callback === 'function') callback(data);
      this.onLoadExecute();
    },
    (data) => {
      this.loadDataDeja = false;
      console.warn(`${actionLoadData} failed`);
      if (errorCallback) errorCallback(data);
    });
  }

  saveDataLocale() {
    Object.keys(this.listeConcept).forEach((conceptClass) => {
      this.listeConcept[conceptClass].saveDataLocale();
    });
  }

  async loadDataLocale() {
    const listAwait = [];
    const debut     = new Date();
    this.configConcept.forEach((conceptClass) => {
      const concept = this.getConcept(conceptClass);
      if (concept) {
        listAwait.push(concept.loadDataLocale());
      }
    });
    const dataLoaded = await Promise.all(listAwait);
    if (dataLoaded.every((loaded) => loaded)) {
      // ensure every loadDataLocale is successful before triggering onLoadExecute
      this._isloaded = true;
      this.onLoadExecute();
    }
    const fin = new Date();
    // eslint-disable-next-line no-console
    console.log(`loadDataLocale terminée en ${Math.round(fin.getTime() - debut.getTime())}ms`);
  }

  doneTransaction() {
    this.onSynchro();
  }

  failTransaction() {
    this.onSynchro();
    // eslint-disable-next-line no-console
    console.log('Perte de connexion');
  }

  setOnSynchroCallback(callback: ()=>void) {
    this.onSynchroCallback = callback;
  }

  onSynchro() {
    if (!this.synchroEnCours) {
      this.synchroEnCours = true;
      setTimeout(() => {
        this.synchroEnCours = false;
      }, 2000);
    }
    if (this.onSynchroCallback) {
      this.onSynchroCallback();
    }
  }

  /**
   * Return the list of object that are waiting to be sent to the server
   * @param db is the customer database each application can store different databases at the same time
   */
  getListeSynchro(db: string): ObjectSynchro[] {
    if (!this.listeSynchro[db]) {
      this.listeSynchro[db] = [];
    }
    return this.listeSynchro[db];
  }

  resetListeSynchro(db: string) {
    this.listeSynchro[db] = [];
  }

  addListeSynchro(db: string, listeSynchro: ObjectSynchro[]) {
    this.getListeSynchro(db).concat(listeSynchro);
    this.storage.set(`${db}.listeSynchro`, this.getListeSynchro(db)).catch((reason: unknown)=> {
      if (typeof reason === 'string') {
        this.toastController
          .create({ position: 'top', message: reason, duration: 4000, color: 'danger' })
          .then((toast) => toast.present());
      }
    });
  }

  resync() {
    this.initListeSynchro();
    window.location.reload();
  }

  initListeSynchro(): ListeSynchro {
    const listeSynchro = {...this.listeSynchro};
    this.databases.forEach((db) => {
      this.resetListeSynchro(db);
    });
    return listeSynchro;
  }

  isEveIdExclus(database, id) {
    return this.listeEveIdExclus[database] && this.listeEveIdExclus[database].includes(id);
  }

  addEveIdsExclus(database: string, listeEveId: Array<string | number>) {
    if (!listeEveId) return;

    // console.log('listeEveId', listeEveId);
    if (!this.listeEveIdExclus[database]) {
      this.listeEveIdExclus[database] = [];
    }
    listeEveId.forEach((id) => {
      this.listeEveIdExclus[database].push(id);
    });
  }

  restartSynchroniseTimeout() {
    if (this.synchronisecache.timeout) {
      clearTimeout(this.synchronisecache.timeout);
    }
    this.synchronisecache.timeout = setTimeout(() => {
      this.synchronisecache.start_since = 0;
      console.debug('Timeout synchronise');
    }, 30000);
  }

  toasterSynchro(message: string, color: 'danger'|'warning', duration:number = 4000): void {
    this.toastController
      .create({ position: 'top', message, duration, color })
      .then((toast) => toast.present());
  }

  getPayloadFromListeSynchro(): SynchronisePayload {
    const listeSynchro: ListeSynchro = this.initListeSynchro();
    const gdsPayload: GdsPayload[]   = [];
    let nb: number                   = 0;
    Object.keys(listeSynchro).forEach((db: string)=> {
      const objectSynchro: ObjectSynchro[] = listeSynchro[db];
      if (objectSynchro.length > 0) {
        nb += objectSynchro.length;
        gdsPayload.push({ limo: db, listeSynchro: objectSynchro });
      }
    });
    return { gdsPayload, listeSynchroSend: listeSynchro, nb }
  }

  /**
   * That method upload local data stored in getListeSynchro
   * those data come from previous manipulation that are stored in the localStorage or in memory
   * The synchronize can fail for some databases and success for others during the same call
   * If the synchronize fail, we put back the data in the getListeSynchro list with the method: addListeSynchro
   * Only for database who failed
   * @param callback oldschool next of a subscribe
   */
  synchronise(callback?: () => void) {

    if (this.synchronisecache.start_since !== 0) {
      if (callback) {
        callback();
      }
      return;
    }

    this.restartSynchroniseTimeout();
    const { gdsPayload, listeSynchroSend, nb} = this.getPayloadFromListeSynchro();

    if (nb === 0) {
      this.synchroDone = true;
      this.onSynchro();
      if (callback) {
        callback();
      }
      return;
    }

    this.synchronisecache.start_since = new Date().getTime();
    /**
     * We send data to the server, if it fails, we put back the data to synchronise
     * in the list with the method: addListeSynchro
     */
    this.gds.post('divers', this.actionSynchro, gdsPayload).subscribe(
      (datas) => {
        Object.keys(datas).forEach((database: string) => {
          const data = datas[database];

          /**
           * When the user reload the page during synchronize, some data in memory can be lost
           * To avoid than, we clean the storage only if the transaction success.
           * In any case the ListeSynchro from the memory is reset during synchronize to be able to catch new events
           */
          if (data === 'synchro_ok') {
            this.storage.set(`${database}.listeSynchro`, this.getListeSynchro(database));
          }

          if (data === 'synchro_ko') {
            console.error('synchro_ko', gdsPayload);
            this.toasterSynchro(this.translate.instant(`Erreur de synchro`), 'danger');
            this.addListeSynchro(database, listeSynchroSend[database]);
          } else if (data === 'connexion_ko') {
            console.error('connexion_ko', gdsPayload);
            this.toasterSynchro(this.translate.instant(`Authentification failed`), 'warning');
            this.addListeSynchro(database, listeSynchroSend[database]);
          } else if (data.statusCode === 500) {
            console.error('statusCode 500', gdsPayload);
            this.toasterSynchro(this.translate.instant(`WAY-Plan : ${database} non disponible. Veuillez contacter l'équipe support`), 'danger');
          } else if (data !== 'synchro_ok') {
            if (this.actionSynchro !== '/wayp/synchronize-partner'){
              this.addEveIdsExclus(database, Array.isArray(data) ? data : data.events);
              return;
            }
            if (data?.events){
              this.getConcept('C_Gds_Evenement_Chauffeur').installEventsLimoDirect(database, data.events)
            }
            this.executePostSaveCallback()
            if (callback) {
              callback();
            }
          }
        });
        this.onSynchro();
        this.synchronisecache.start_since = 0;
        this.doneTransaction();
      },
      () => {
        Object.keys(listeSynchroSend).forEach((database) => {
          this.addListeSynchro(database, listeSynchroSend[database]);
        });
        this.toasterSynchro(this.translate.instant(`Pas de connexion pour le moment`), 'warning', 1000);
        this.synchronisecache.start_since = 0;
        this.failTransaction();
      });
  }

  setDatas(datas, flagRemoveData = false) {
    Object.keys(datas).forEach((database) => {
      if (datas[database].error) {
        console.error(`Erreur ${  database  } : ${  datas[database].error}`);
      } else {
        // console.log(datas[database]);
        const { data, champs, increments, constantes } = datas[database];
        // pour avoir dans l'ordre de chargement optimal ( du plus général au plus precis)
        this.configConcept.forEach((conceptName) => {
          const concept = this.getConcept(conceptName);
          if (data && data[conceptName]) {
            concept.setRowsetAvecNomChamp(
              data[conceptName],
              champs[conceptName],
              database,
              flagRemoveData,
            );
          } else if (concept) {
            concept.setRowsetAvecNomChamp([], champs[conceptName], database, flagRemoveData);
          }
          if (increments[concept.name]) {
            this.storage.set(`${database}.increment.${concept.name}`, 0);
            // console.log('set lastId=',database,concept.name,increments[concept.name]);
            concept.lastId = 0; // increments[concept.name];
          }
        });
        this.setConstantes(database, constantes);
        this.conceptEvc.setLastEveId(database, datas[database].lastEveId);
      }
    });
  }

  setConstantes(database, constantes) {
    this._constantes[database] = constantes;
    this.storage.set(`${database}.constantes`, constantes);
  }

  getConstante(database, name) {
    const db = database === null ? this.getInstallationPrincipal().INS_COMPTE : database;
    return this._constantes[db] ? this._constantes[db][name] : null;
  }

  addMessage(chaine) {
    this.toastController
      .create({
        position: 'top',
        message : this.translate.instant(chaine),
        duration: 4000,
        color   : 'warning',
      })
      .then((toast) => toast.present());
  }

  async addSynchro(database: string, type: 'insert' | 'delete' | 'update', name: string, row: object, callback?) {
    this.onSynchro();
    this.getListeSynchro(database).push({type, name, row});
    this.storage.set(`${database}.listeSynchro`, this.getListeSynchro(database)).then(()=>{
      if (this.synchroDone) {
        this.synchroDone = false;
        this.synchronise(callback);
      }
    });
  }

  executePostSaveCallback():void {
    if(!this.postSaveCallbacks.length) return
    this.postSaveCallbacks.forEach(callback => callback())
    this.postSaveCallbacks = []
  }
}
