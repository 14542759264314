import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@way-lib/common/services/auth/auth.guard';

const routes: Routes = [
  {
    path      : '',
    redirectTo: 'home',
    pathMatch : 'full',
  },
  {
    path        : 'home',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('way-partner/src/app/pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path        : 'dashboard',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('way-partner/src/app/pages/dashboard/dashboard.module').then(
        (m) => m.DashboardPageModule,
      ),
  },
  {
    path      : 'auth',
    redirectTo: 'signin',
    pathMatch : 'full',
  },
  {
    path        : 'signin',
    loadChildren: () =>
      import('way-partner/src/app/pages/auth/signin/signin.module').then((m) => m.SigninPageModule),
  },
  {
    path        : 'signup',
    loadChildren: () =>
      import('way-partner/src/app/pages/auth/signup/signup.module').then((m) => m.SignupPageModule),
  },
  {
    path        : 'vehicle',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('way-partner/src/app/pages/vehicle/vehicle.module').then((m) => m.VehicleModule),
  },
  {
    path        : 'customer',
    canActivate : [AuthGuard],
    loadChildren: () => import('@way-lib/client/client.module').then((m) => m.ClientModule),
  },
  {
    path        : 'notifications',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('@way-lib/notification/notifications.module').then((m) => m.NotificationsPageModule),
  },
  {
    path        : 'missions',
    canActivate : [AuthGuard],
    data        : { editable: true },
    loadChildren: () =>
      import('way-partner/src/app/pages/missions/missions.module').then((m) => m.MissionsModule),
  },
  {
    path        : 'bourse',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('way-partner/src/app/pages/bourse/bourse.module').then((m) => m.BourseModule),
  },
  {
    path        : 'partners',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('way-partner/src/app/pages/partners/partners.module').then((m) => m.PartnersModule),
  },
  {
    path        : 'cercles',
    canActivate : [AuthGuard],
    loadChildren: () => import('@way-lib/cercle/cercle.module').then((m) => m.CercleModule),
  },
  {
    path        : 'accounting',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('way-partner/src/app/pages/accounting/accounting.module').then(
        (m) => m.AccountingPageModule,
      ),
  },
  {
    canActivate : [AuthGuard],
    path        : 'agenda',
    loadChildren: () =>
      import('@way-lib/agenda-driver/agenda-driver.module').then((m) => m.AgendaDriverModule),
  },
  {
    path        : 'parameters',
    canActivate : [AuthGuard],
    data        : { onlyAuthCheck: true },
    loadChildren: () =>
      import('way-partner/src/app/pages/parameter/parameter.module').then(
        (m) => m.ParameterPageModule,
      ),
  },
  {
    path        : 'panneau/:panneau/:urlLogo',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('@way-lib/client/pages/panneau/panneau.module').then((m) => m.PanneauPageModule),
  },
  {
    path        : 'profile',
    canActivate : [AuthGuard],
    loadChildren: () =>
      import('way-partner/src/app/pages/profile/profile.page.module').then(
        (m) => m.ProfilePageModule,
      ),
  },
  {
    path      : '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
