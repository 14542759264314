import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Jaf } from '@way-lib/common/services/jaf/jaf';
import { Subscription } from 'rxjs';

@Component({
  selector   : 'way-partner-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls  : ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit, OnDestroy {
  @Input() id: string;

  @Input() type: 'time' | 'duration';

  @Input() control: FormControl;

  private subscription: Subscription;

  public showClock: boolean = false;

  public Jaf = Jaf;

  ngOnInit(): void {
    this.subscription = this.control.valueChanges.subscribe((value) => {
      if (!value) return;
      this.control.setValue(Jaf.time2mysql(this.durationMode() ? `${value}:00` : value), {
        emitEvent: false,
      });
    });
  }

  toggleClockModal(state: boolean): void {
    this.showClock = state;
  }

  onBlur(): void {
    if (!this.control.value) {
      this.control.setValue(this.durationMode() ? '00:00:00' : Jaf.time2mysql(new Date()));
    }
  }

  durationMode(): boolean {
    return this.type === 'duration';
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
