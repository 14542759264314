import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { NavigationEnd, Router } from '@angular/router';
import { IonMenu } from '@ionic/angular';
import { SubscriptionsService } from '@way-lib/common/services/subscriptions.service';
import { MenuService } from '../../services/menu.service';

type NavigationItem = {
  title: string;
  url?: any[] | string | null | undefined;
  click?: any;
  icon: string;
  liste?: any;
};

@Component({
  selector   : 'way-partner-menu',
  templateUrl: './menu.component.html',
  styleUrls  : ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  @Input() disabled: any;

  @ViewChild('menu', { static: true })
  menu: IonMenu;

  navigationItems: NavigationItem[];

  routeSubscription: Subscription;

  subscriptions: Subscription[] = [];

  disabledMenu: boolean = false;

  constructor(
    private router: Router,
    private menuService: MenuService,
    public subscriptionService: SubscriptionsService,
  ) {}

  ngOnInit(): void {
    this.navigationItems = this.menuService.main;

    this.subscriptions.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.disabledMenu = event.url === '/signup' || event.url === '/signin';
        }
      }),
      this.subscriptionService.subscriptions$.subscribe((subscription) => {
        this.disabledMenu = !this.subscriptionService.hasActiveSubscription(subscription);
      }),
    );
  }

  get isMenuDisabled(): boolean {
    return this.disabledMenu;
  }

  public isUnsubscribedPage(page: NavigationItem): boolean {
    if (!page.url || page.url[0] === '/parameters') {
      return true;
    }
    return false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
