import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { QuotedVehicle } from '../../pages/missions/mission-form/mission-form-types';

@Component({
  templateUrl: './quoted-vehicle-picker.component.html',
  styleUrls  : ['./quoted-vehicle-picker.component.scss'],
})
export class QuotedVehiclePickerModalComponent {
  @Input() quotedVehicles: { vehiclePlate: string; value: QuotedVehicle };

  @Input() selected: QuotedVehicle;

  constructor(public modalController: ModalController) {}

  public selectQuotedVehicle(quotedVehicle: QuotedVehicle): void {
    this.modalController.dismiss(quotedVehicle);
  }
}
