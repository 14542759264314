import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@way-lib/common/services/auth/auth.service';
import { ConceptManager } from '@way-lib-jaf/concept-manager';

export enum TabAction {
  TOGGLE_MENU = 'TOGGLE_MENU',
}

export interface TabsMenuItem {
  label?: string;
  route?: Array<string>;
  icon: string;
  iconSize?: string;
  class?: string;
  color?: string;
  list?: Array<any>;
  action?: TabAction;
}

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  public tabs: TabsMenuItem[] = [
    {
      icon : 'layout-dashboard',
      route: ['/dashboard'],
      class: 'tab-icon',
      label: this.translate.instant('Accueil'),
    },
    {
      icon : 'calendar-event',
      class: 'tab-icon',
      route: ['/missions', 'list', 'inprocess'],
      label: this.translate.instant('Agenda'),
    },
    {
      icon    : 'plus',
      class   : 'create-mission-btn',
      iconSize: 'lg',
      color   : 'l1',
      route   : ['/missions', 'create'],
    },
    {
      icon : 'receipt-euro',
      class: 'tab-icon',
      route: ['/accounting', 'bills', 'billable'],
      label: this.translate.instant('Factures'),
    },
    {
      icon  : 'menu-deep',
      color : 'brand',
      class : 'tab-icon menu-btn',
      label : this.translate.instant('Plus'),
      action: TabAction.TOGGLE_MENU,
    },
  ];

  main = [
    {
      title: "Bourse d'échange",
      url  : ['/bourse'],
      icon : 'world-share',
      liste: this.cm.getConcept('C_Gds_Dtr').available,
    },
    {
      title: 'Cercles',
      url  : ['/cercles'],
      icon : 'atom-2',
    },
    {
      title: 'Partenaires',
      url  : ['/partners'],
      icon : 'steering-wheel',
    },
    {
      title: 'Clients',
      url  : ['/customer'],
      icon : 'users',
    },
    {
      title: 'Véhicules',
      url  : ['/vehicle'],
      icon : 'car',
    },
    {
      title: 'Mon profil',
      url  : ['/profile'],
      icon : 'user',
    },
    {
      title: 'Mes disponibilités',
      url  : ['/agenda', 'availability'],
      icon : 'calendar-month',
    },
    {
      title: 'Paramètres',
      url  : ['/parameters'],
      icon : 'settings',
    },
    {
      title: 'Resynchroniser',
      click: () => this.cm.resync(),
      icon : 'rotate-clockwise-2',
    },
    {
      title: 'Déconnexion',
      click: () => this.authService.logout(true),
      icon : 'logout',
    },
  ];

  billing = [
    // { index: 'all', route: ['/accounting', 'bills', 'all'], label: 'Toutes' },
    { index: 'billable', route: ['/accounting', 'bills', 'billable'], label: 'À facturer' },
    { index: 'list', route: ['/accounting', 'bills', 'list'], label: 'Factures' },
  ];

  quotes = [
    { index: 'pending', route: ['/accounting', 'quotes', 'pending'], label: 'En attente' },
    { index: 'accepted', route: ['/accounting', 'quotes', 'accepted'], label: 'Acceptés' },
    { index: 'denied', route: ['/accounting', 'quotes', 'denied'], label: 'Refusés' },
  ];

  accounting = [
    { index: 'billing', route: ['/accounting', 'bills', 'billable'], label: 'Facturation' },
    { index: 'quotes', route: ['/accounting', 'quotes', 'pending'], label: 'Devis' },
  ];

  constructor(
    private authService: AuthService,
    private cm: ConceptManager,
    private translate: TranslateService,
  ) {}
}
