import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector   : 'circle-progress-bar',
  templateUrl: './circle-progress-bar.component.html',
  styleUrls  : ['./circle-progress-bar.component.scss'],
})
export class CircleProgressBarComponent implements OnChanges {
  @Input() value: number = 0;

  @Input() max: number = 100;

  @Input() mode: 'light' | 'dark' = 'light';

  strokeDashoffset: number = 0;

  strokeDasharray: string = '';

  percentage: string = '';

  private radius: number = 20;

  private circumference: number = 2 * Math.PI * this.radius;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value || changes.max) {
      const percentage      = (this.value / this.max) * 100;
      this.strokeDashoffset = this.circumference - (percentage / 100) * this.circumference;
      this.strokeDasharray  = `${this.circumference} ${this.circumference}`;
      this.percentage       = `${Math.round((this.value / this.max) * 100)}%`;
    }
  }
}