<div class="progress-container">
  <svg class="progress-ring" width="40" height="40">
    <circle
      class="progress-ring__background"
      [attr.stroke]="mode === 'dark' ? '#E5CEEC' : '#935BA2'"
      stroke-width="5"
      fill="transparent"
      r="15"
      cx="20"
      cy="20"
    ></circle>
    <circle
      class="progress-ring__circle"
      [attr.stroke]="mode === 'dark' ? '#491057' : '#fff'"
      stroke-width="5"
      fill="transparent"
      r="15"
      cx="20"
      cy="20"
      stroke-linecap="round"
      [attr.stroke-dasharray]="strokeDasharray"
      [attr.stroke-dashoffset]="strokeDashoffset"
    ></circle>
  </svg>
  <div class="progress-text" [ngClass]="{ dark: mode === 'dark' }">{{ value }}/{{ max }}</div>
</div>
