import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionsService } from '@way-lib/common/services/subscriptions.service';
import { WayModeService } from '@way-lib/common/services/way-mode.service';
import { MenuService, TabAction, TabsMenuItem } from '../../services/menu.service';

@Component({
  selector   : 'way-partner-nav-tabs',
  templateUrl: './nav-tabs.component.html',
  styleUrls  : ['./nav-tabs.component.scss'],
})
export class NavTabsComponent implements OnInit {
  @Input() onlyToolbar: boolean = true;

  public tabItems: Array<TabsMenuItem>;

  public hasSubscription = false;

  public TabAction = TabAction;

  constructor(
    private subscriptionService: SubscriptionsService,
    private menuService: MenuService,
    public wayModeService: WayModeService,
  ) {}

  ngOnInit() {
    this.tabItems = this.menuService.tabs;
    this.subscriptionService.subscriptions$.subscribe((subscription) => {
      this.hasSubscription = this.subscriptionService.hasActiveSubscription(subscription);
    });
  }
}
