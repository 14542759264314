<ion-tab-bar
  [ngClass]="{ onlyToolbar: onlyToolbar }"
  contain="none"
  slot="bottom"
  *ngIf="tabItems.length"
>
  <ng-container *ngFor="let tabItem of tabItems">
    <ion-tab-button
      *ngIf="!tabItem.action; else actionTab"
      [routerLinkActive]="'tab-selected'"
      [routerLink]="tabItem.route"
      [ngClass]="[tabItem.class, !hasSubscription ? 'disabled' : '']"
      >
      <tabler-icon
        aria-hidden="true"
        [icon]="tabItem.icon"
        [color]="tabItem.color"
        [size]="tabItem.iconSize ? tabItem.iconSize : 'lg'"
      ></tabler-icon>
      <ion-label *ngIf="tabItem.label" class="tab-label">{{ tabItem.label }}</ion-label>
      <ion-badge color="danger" *ngIf="tabItem.list && tabItem.list.length > 0">{{
        tabItem.list.length
      }}</ion-badge>
    </ion-tab-button>
    <ng-template #actionTab>
      <ng-container *ngIf="tabItem.action === TabAction.TOGGLE_MENU">
        <ion-tab-button [ngClass]="tabItem.class">
          <ion-menu-button>
            <tabler-icon
              aria-hidden="true"
              [icon]="tabItem.icon"
              [color]="tabItem.color ? tabItem.color : 'd1'"
              [size]="tabItem.iconSize ? tabItem.iconSize : 'lg'"
            ></tabler-icon>
          </ion-menu-button>
          <ion-label *ngIf="tabItem.label" class="tab-label">{{ tabItem.label }}</ion-label>
        </ion-tab-button>
      </ng-container>
    </ng-template>
  </ng-container>
</ion-tab-bar>
