<ion-menu
  #menu
  contentId="main-content"
  type="overlay"
  [disabled]="disabledMenu"
  [swipeGesture]="!disabledMenu"
>
  <ion-content class="menu-content">
    <ion-list class="navigation-list">
      <ion-menu-toggle *ngFor="let navigationItem of navigationItems">
        <ng-container
          *ngIf="subscriptionService.hasActiveSubscription$ | async; else noActiveSubscription"
        >
          <div
            *ngIf="!navigationItem.click; else clickItem"
            class="navigation-item"
            detail="false"
            lines="none"
            [routerLinkActive]="'selected'"
            [routerLink]="navigationItem.url"
          >
            <div class="icon-circle">
              <tabler-icon [icon]="navigationItem.icon" size="md"></tabler-icon>
            </div>
            <span class="navigation-item-title">{{ navigationItem.title }}</span>
          </div>
          <ng-template #clickItem>
            <div
              class="navigation-item"
              detail="false"
              lines="none"
              (click)="navigationItem.click()"
            >
              <div class="icon-circle">
                <tabler-icon [icon]="navigationItem.icon" size="md"></tabler-icon>
              </div>
              <span class="navigation-item-title">{{ navigationItem.title }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-template #noActiveSubscription>
          <ng-container *ngIf="isUnsubscribedPage(navigationItem)">
            <div
              *ngIf="!navigationItem.click; else clickItem"
              class="navigation-item"
              detail="false"
              lines="none"
              [routerLinkActive]="'selected'"
              [routerLink]="navigationItem.url"
            >
              <div class="icon-circle">
                <tabler-icon [icon]="navigationItem.icon" size="md"></tabler-icon>
              </div>
              <span class="navigation-item-title">{{ navigationItem.title }}</span>
            </div>
            <ng-template #clickItem>
              <div
                class="navigation-item"
                detail="false"
                lines="none"
                (click)="navigationItem.click()"
              >
                <div class="icon-circle">
                  <tabler-icon [icon]="navigationItem.icon" size="md"></tabler-icon>
                </div>
                <span class="navigation-item-title">{{ navigationItem.title }}</span>
              </div>
            </ng-template>
          </ng-container>
        </ng-template>
      </ion-menu-toggle>
    </ion-list>
  </ion-content>
</ion-menu>
